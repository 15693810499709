<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_cima_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Moradoras de Paracatu de Cima na janela e porta da casa. Foto: Lucas
            de Godoy/Jornal A Sirene, 2017.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A ocupação territorial de Paracatu de Cima caracteriza-se por
                propriedades rurais distribuídas de forma espalhada ao longo do
                Rio Gualaxo do Norte. Os sítios e fazendas, como na origem de
                formação do povoado, continuam destinados ao plantio de
                lavouras, a pastagens e criação de animais, principalmente gado
                bovino leiteiro. A produção em sua maioria atende ao autoconsumo
                das famílias, bem como faz parte das trocas com outros
                produtores locais. Parte é comercializada com outras comunidades
                da região, a exemplo de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_aguas_claras' }"
                  >Águas Claras</router-link
                >, para onde o leite produzido era direcionado, havendo no
                distrito a Associação dos Produtores de Leite de Águas Claras e
                Região, organizada para conectar os pecuaristas do território,
                valorizando a produção por meio da comercialização direta com a
                indústria.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                As maiores extensões das terras eram então dedicadas aos
                cultivos e espaços de criação animal, bem como ocupadas por
                áreas de mata nativa, que se estendem pelas serras do entorno.
                As construções de uso rural como galinheiros, chiqueiros,
                currais, estábulos, paióis, foram implantadas geralmente em
                planícies, próximas aos caminhos internos e em pontos
                estratégicos para possibilitar a logística de manejo das
                atividades produtivas. As
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_casas' }"
                  >casas</router-link
                >
                em sua maioria também foram construídas nas áreas mais planas
                dos terrenos, próximas às estradas internas e de sistemas
                produtivos como a horta, o pomar e o galinheiro.
              </p>
              <p>
                Diferindo da vizinha Paracatu de Baixo, não possui um núcleo
                urbano definido, havendo, entretanto, uma igreja em louvor a
                Nossa Senhora do Carmo, construída entre 1980 e 1990, com
                aparência simples e um cruzeiro localizado próximo à ponte de
                acesso à comunidade.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_cima_02.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de um terreno em Paracatu de Cima com relevo montanhoso,
            ocupado em parte por mata e parte por extensões de pastagem. Na
            porção centralizada da imagem, encontram-se as edificações e
            cultivos como horta e pomar. Foto: Vistoria realizada pela Cáritas,
            2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_cima_03.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da horta situada próxima da casa, em um terreno de Paracatu de
            Cima. Foto: Vistoria realizada pela Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A socialização ocorria essencialmente nas relações de trabalho e
                cooperação. O convívio entre os moradores acontecia ainda
                durante as atividades de lazer, principalmente no Rio Gualaxo do
                Norte quando eles pescavam e se banhavam. Ressalta-se ainda que
                parte dos terrenos possuía campo de futebol e tanques de criação
                de peixes.
              </p>
              <p>
                Além das relações sociais e de lazer que ocorriam na própria
                comunidade, os moradores de Paracatu de Cima possuíam estreitos
                laços com a vizinha Paracatu de Baixo e participavam ativamente
                das celebrações e festejos tradicionalmente praticados. Da mesma
                forma, as festividades de Paracatu de Cima recebiam os vizinhos
                próximos. Com a passagem da lama e a mudança forçada da maior
                parte da população de Paracatu de Baixo para a sede de Mariana,
                muitos de seus antigos habitantes não conseguiram continuar a
                frequentar as festas de Paracatu de Cima, diminuindo muito o
                público participante das celebrações e prejudicando as trocas
                culturais ali realizadas há várias gerações.
              </p>
              <p>
                Entre os danos relacionados com a passagem violenta da lama
                tóxica pela região está a destruição de plantações, pastos,
                abastecimento hídrico e impedimento das práticas de lazer. A
                devastação de Paracatu de Baixo, comunidade bem próxima e com a
                qual mantinha relações econômicas, causou enormes prejuízos aos
                produtores de Paracatu de Cima, que atualmente reduziram parte
                dos sistemas produtivos ou até mesmo interromperam a produção
                por falta de mão de obra, de compradores ou fornecedores que
                forçosamente se mudaram para outros locais.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
          style="z-index: 2"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Cultura e Patrimônio"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Construída entre as décadas de 1980 a 1990, a igreja e o
                cruzeiro localizados próximos à ponte de acesso consistem em
                ponto de referência para a comunidade. Com características
                simples contemporâneas, possui volumetria prismática com planta
                retangular, onde as fachadas frontal e posterior apresentam
                menores dimensões que as laterais. A construção foi feita com
                alvenaria autoportante de tijolos cerâmicos e cobertura disposta
                em duas águas. A fachada frontal é composta por três vãos, sendo
                uma porta central e duas janelas, não havendo ornamentos nem
                ressaltos e rebaixos. Na empena — parte superior e triangular da
                fachada frontal — foi inscrita com pintura a frase: “Igreja
                Nossa Senhora do Carmo – Comunidade Francisco Ladislau Tavares”,
                que a identifica. Internamente, o templo apresenta piso cerâmico
                e forro em lambri, com decoração modesta composta por bancos de
                madeira, uma mesa de altar, um sacrário e suportes de madeira
                fixados nas quinas das paredes onde descansam as imagens de
                devoção. Na parte posterior da igreja, encontra-se a sacristia.
                A edificação está localizada em terreno cercado por muro de
                blocos de concreto e portão metálico, por onde se faz o acesso.
              </p>
              <p>
                A igreja abriga as festividades em honra à padroeira de mesmo
                nome, assim como a Nossa Senhora Aparecida e a Santa Luzia, além
                da tradicional Festa de Santa Cruz presente em todo o território
                do Gualaxo do Norte. O templo de pequenas dimensões destina-se
                ao encontro entre os moradores da comunidade nas celebrações ali
                realizadas. Ressalta-se que, no passado, Santa Cruz foi
                considerada patrona da localidade, com a substituição por Nossa
                Senhora do Carmo na mesma época em que a Arquidiocese de Mariana
                alterou os padroeiros em outras comunidades, como Campinas.
              </p>
              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
          style="z-index: 2"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_cima_04.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de uma casa situada em Paracatu de Cima, implantada na área
            plana do terreno. Foto: Vistoria realizada pela Cáritas, 2019.
          </p>
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_cima_05.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Campo de futebol de Paracatu de Baixo, utilizado como atividade de
            lazer também por Paracatu de Cima antes do rompimento. Foto: Flora
            Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="pb-5">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
